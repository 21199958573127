import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import trainStops from './train-stops.png';
import nestingTree from './nesting-tree.png';
import reservationStatusChange from './reservation-status-change.png';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <h2 {...{
      "id": "departures"
    }}>{`Departures`}</h2>
    <p>{`Departures in the inventory is identified by originalDatedServiceJourneyIds. The inventory is made up by quotas and reservations which are both added to a dated service journey.`}</p>
    <h3 {...{
      "id": "dated-service-journeys"
    }}>{`Dated Service Journeys`}</h3>
    <p>{`A dated service journey represents a departure on a specific date. It contains the following fields:`}</p>
    <ul>
      <li parentName="ul">{`id, the originalDatedServiceJourneyId, e.g. ENT:DatedServiceJourney:905144.`}</li>
      <li parentName="ul">{`invertedDirection, indicates which direction this journey travels, TRUE or FALSE.`}</li>
      <li parentName="ul">{`lineId, the id of the line this journey travels on, e.g. VYG:Line:57 (Bergensbanen).`}</li>
    </ul>
    <p>{`DatedServiceJourneyIds is defined by Entur's timetable service.`}</p>
    <h3 {...{
      "id": "lines"
    }}>{`Lines`}</h3>
    <p>{`A line is a sequence of stop places that a dated service journey travels, it contains the following fields:`}</p>
    <ul>
      <li parentName="ul">{`id, the id for the line, e.g. VYG:Line:57.`}</li>
      <li parentName="ul">{`version, the version of this line, e.g. 1.`}</li>
      <li parentName="ul">{`stops, the list of stop places that is traversed by the line, e.g. NSR:StopPlace:337, NSR:StopPlace:451, ...`}</li>
    </ul>
    <h2 {...{
      "id": "quotas"
    }}>{`Quotas`}</h2>
    <p>{`A quota describes the limit of a resource, e.g. discounted tickets on a specific dated service journey. The constrained resource is called a "product", this is not necessarily a product in the NetEx sense, it might e.g. be a FareQuotaFactor. The inventory does not attach any semantics to the "product", it only use an ID which can be counted and consumed/reserved. For instance we can have a quota of 5 "ENT:DiscountTicket:1" and if someone reserves 2 of that "product" we can calculate that there are 3 "ENT:DiscountTicket:1" left available.`}</p>
    <p>{`A quota can restrict multiple products, i.e. the consumption of separate products can decrement the availability of the same quota. E.g. a quota might restrict that a maximum of 10 "product x" and "product y" can be consumed. If a reservation of 3 "product x" and 3 "product y" is registered, the availability (remaining quantity) of this quota would be 4.`}</p>
    <p>{`A departure might include many quotas of the same product. If a traveller desires to travel from A to B, a subset of the quotas can apply. The applicability of a quota is determined by the desired travel segment and the quota-properties ods and useStoplist. This is further explained in the section `}<a parentName="p" {...{
        "href": "#quota-behavior"
      }}>{`Quota behavior`}</a>{`.`}</p>
    <h3 {...{
      "id": "api"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /v1/quotas/:quotaId" mdxType="ExpandablePanel">
Example response GET /v1/quotas/99
      <pre>
        {`{
    "id": 99,
    "quota": 10,
    "products": [
        "ABC:PreassignedFareProduct:Ticket"
    ],
    "ods": [],
    "quotaConfigurationId": 88,
    "datedServiceJourneyId": "ENT:DatedServiceJourney:2000",
    "purchaseWindowStart": "2018-12-11T09:31:32.839Z",
    "purchaseWindowStop": "2018-12-15T09:31:32.839Z",
    "useStoplist": true
}`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="GET /v1/quotas?datedServiceJourney=:dateServericeJourneyId" mdxType="ExpandablePanel">
Example response GET /v1/quotas?datedServiceJourney=ENT:DatedServiceJourney:2000
      <pre>
        {`[
 {
    "id": 99,
    "quota": 10,
    "products": [
        "ABC:PreassignedFareProduct:Ticket"
    ],
    "ods": [],
    "quotaConfigurationId": 88,
    "datedServiceJourneyId": "ENT:DatedServiceJourney:2000",
    "purchaseWindowStart": "2018-12-11T09:31:32.839Z",
    "purchaseWindowStop": "2018-12-15T09:31:32.839Z",
    "useStoplist": true
 }
]`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/quotas" mdxType="ExpandablePanel">
Example post request:
      <pre>
        {`[
  {
    "quota": 10,
    "products": ["ABC:PreassignedFareProduct:Ticket"],
    "ods": [],
    "useStopList": true,
    "datedServiceJourneyId": "ENT:DatedServiceJourney:2838"
  }
]`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/quotas?datedServiceJourney=:datedServiceJourney" mdxType="ExpandablePanel">
Example request PUT /v1/quotas?datedServiceJourney=ENT:DatedServiceJourney:2838
      <pre>
        {`[
  {
    "id": 99,
    "quota": 20,
    "products": ["ABC:PreassignedFareProduct:Ticket"],
    "ods": [],
    "useStopList": true
  }
]`}
      </pre>
Example response PUT /v1/quotas?datedServiceJourney=ENT:DatedServiceJourney:2838
      <pre>
        {`[
  {
    "id": 99,
    "quota": 20,
    "products": ["ABC:PreassignedFareProduct:Ticket"],
    "ods": [],
    "useStopList": true,
    "datedServiceJourneyId": "ENT:DatedServiceJourney:2838"
  }
]`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/quotas/:quotaId" mdxType="ExpandablePanel">
Example request PUT /v1/quotas/99
      <pre>
        {`
  {
    "quota": 20,
    "products": ["ABC:PreassignedFareProduct:Ticket"],
    "ods": [],
    "useStopList": true
  }
`}
      </pre>
Example response PUT /v1/quotas/99
      <pre>
        {`
  {
    "id": 99,
    "quota": 20,
    "products": ["ABC:PreassignedFareProduct:Ticket"],
    "ods": [],
    "useStopList": true,
    "datedServiceJourneyId": "ENT:DatedServiceJourney:2838"
  }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="DELETE /v1/quotas/:quotaId" mdxType="ExpandablePanel">
Response 204 if quota is deleted.
    </ExpandablePanel>
    <h3 {...{
      "id": "entity"
    }}>{`Entity`}</h3>
    <p>{`A quota consists of the following information:`}</p>
    <ul>
      <li parentName="ul">{`quota: The restricted quantity`}</li>
      <li parentName="ul">{`products: A list of product-ids the quota should restrict`}</li>
      <li parentName="ul">{`ods: The origin and destination pairs for this quota (see `}<a parentName="li" {...{
          "href": "#quota-behavior"
        }}>{`Quota behavior`}</a>{`)`}</li>
      <li parentName="ul">{`useStoplist: Whether to "flatten" non-overlapping reservations or not (see `}<a parentName="li" {...{
          "href": "#quota-behavior"
        }}>{`Quota behavior`}</a>{`)  `}</li>
      <li parentName="ul">{`datedServiceJourney: The originalDatedServiceJourneyId of the departure this quota should apply to`}</li>
      <li parentName="ul">{`quotaConfiguration: Optional - Reference to the quota-configuration used by this quota (see `}<a parentName="li" {...{
          "href": "#nesting"
        }}>{`Nesting`}</a>{`)`}</li>
      <li parentName="ul">{`purchaseWindowStart: Optional - The start of the purchaseWindow on the associated quota (see `}<a parentName="li" {...{
          "href": "#stock-overrides"
        }}>{`Stock overrides`}</a>{`)`}</li>
      <li parentName="ul">{`purchaseWindowStop: Optional - The end of the purchaseWindow on the associated quota (see `}<a parentName="li" {...{
          "href": "#stock-overrides"
        }}>{`Stock overrides`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "quota-behavior"
    }}>{`Quota behavior`}</h3>
    <p>{`Currently we have defined 4 possible quota behaviors which are defined by two binary properties: a boolean property use_stoplist and the presence of origin-destination pair(s) in the od-list defined on each quota.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Use stoplist`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Od-list on quota`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Resulting quota behavior`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales quota`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stoplist quota`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Non-empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Point-to-point quota`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Non-empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Confined stoplist quota`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "sales-quota"
    }}>{`Sales quota`}</h4>
    <p>{`A sales quota is a quota that is not reusable. That means the resource/product is not released after a passenger has disembarked. Traditional examples are sleeping cabins that are booked for the entire journey, even if the cabin is booked between intermediary stations. In a query for availability, a simple #(quota quantity) - #(sum(reserved quantities)) is performed. Even if the travel segments of 2 reservations does not overlap it is still counted as a decrement of 2 from availability. A sales quota applies to all travel searches.`}</p>
    <h4 {...{
      "id": "stoplist-quota"
    }}>{`Stoplist quota`}</h4>
    <p>{`A stoplist quota is used for reusable resources that are released after a passenger disembarks. Typical examples consist reusable resources such as seats and limited ticket groups (as part of yield management). The quota determines the maximal number of the products that can be reserved between any two stations. That is, the quota quantity is the maximal quantity that can be consumed by reservations crossing any neighboring stations. The calculation of availability is as follows: #(quota quantity) - #(max(sum_station(reservation))). The latter expression represents the maximal reserved quantity between two neighboring stations, considering reservations crossing the stations. Like a sales quota, a stoplist quota applies to all travel searches.`}</p>
    <h4 {...{
      "id": "point-to-point-quota-p2p"
    }}>{`Point-to-point quota (P2P)`}</h4>
    <p>{`A point-to-point quota behaves as a simple counter restraining the travel between a specific set of stations. A P2P quota has a set of origin-destination pairs, and only applies if the potential traveller want to travel from o1 to d1 and (o1,d1) is in the od-list belonging to the quota.`}</p>
    <h4 {...{
      "id": "confined-stoplist-quota"
    }}>{`Confined stoplist quota`}</h4>
    <p>{`A confined stoplist quota is used for limiting travellers crossing a specific segment of a journey. This allows a revenue management optimizer to restrict the availability of "products" for travel searches across that segment, thus pushing prices upwards on journey-segments where seats are limited. Confined stoplist quotas apply to reservations where the travel segment overlaps with one of the segments defined by ods the od-list. The availability is calculated in the same way as stoplist quotas, except we only consider reservations which overlap with the segments defined by the od-list.`}</p>
    <h3 {...{
      "id": "quota-examples"
    }}>{`Quota examples`}</h3>
    <p>{`The following examples use this picture to illustrate different quota setups:`}</p>
    <figure>
    <img src={trainStops} alt="Reservations on a departure between five different train stops" />
    <figcaption>
        The arrows represent a single reservation of a product, and all quotas in these examples have the same product in their product-list.
    </figcaption>
    </figure>
    <ExpandablePanel title="Sales quota" mdxType="ExpandablePanel">
    We can setup a sales quota which limit the allowed amount to sell of this product. As sales quotas are fit for sleeping cabins, say there might only be 10 of those on a train departure. Then, a traveller is interested in booking a cabin from Lillestrøm to Oslo Lufthavn and we therefore ask for stock between those stations. With a quota of 10 sleeping cabins in total, we see that there exists 3 reservations on the train already. Only 1 of the cabins are booked between Lillestrøm and Oslo Lufthavn, but since reservation of a sales quota apply to the whole journey the available stock is 7 for all combinations of origin and destination.
    </ExpandablePanel>
    <ExpandablePanel title="Stoplist quota" mdxType="ExpandablePanel">
    <p>
        Stoplist quotas are setup with a fixed quota which apply between every neighboring station. We can setup a quota of 10 products that are available. With the reservations seen above, the available stock differs based on where the potential traveller wants to travel. Between Oslo S and Oslo Lufthavn there are 9 available reservable products, while between Oslo Lufthavn and Hamar there is only 8 due to overlapping of the second and third reservations.
    </p>
    <table>
        <caption>
            Example with a stoplist quota of 10
        </caption>
        <thead>
            <tr><th><b>From</b></th><th colSpan="4" align="center"><b>To</b></th></tr>
            <tr><td></td><td>Lillestrøm</td><td>Oslo Lufthavn</td><td>Hamar</td><td>Lillehammer</td></tr>
        </thead>
        <tbody>
            <tr><td>Oslo S        </td><td> 9 </td><td> 9  </td><td> 8 </td><td>  8 </td></tr>
            <tr><td>Lillestrøm    </td><td>   </td><td> 9  </td><td> 8 </td><td>  8 </td></tr>
            <tr><td>Oslo Lufthavn </td><td>   </td><td>    </td><td> 8 </td><td>  8 </td></tr>
            <tr><td>Hamar         </td><td>   </td><td>    </td><td>   </td><td>  9 </td></tr>
        </tbody>
    </table>
    </ExpandablePanel>
    <ExpandablePanel title="Point-to-point quota" mdxType="ExpandablePanel">
    <p>
        Point-to-point quotas can be used to fine-tune quota behavior. Usually this quota is used as a means to override other quotas. For instance, if there exists a departure with 20 stops and there is a large demand for travelling from the 10'th to the 11'th stop, these travellers can block the train for travellers going the whole distance (e.g. they reserve seats). For instance with a departure with a stoplist-quota of 20 and adding a quota of 5 with an od-pair (10'th, 11'th), there can only be a total of 5 people travelling between those exact two stops. A travel search from the 10'th station to the 20'th would have 20 available stock, while a query from the 10'th to the 11'th station would only have 5 available. This way, it is possible to sell (relatively) expensive tickets for people travelling in the segment with high demand, and (relatively) cheap tickets for long-distance travellers.
    </p>
    <p>
        The other use-case is to micro-manage every possible journey combination by adding od-quotas between all possible od-combination. This gives the highest degree of flexibility, but is harder to maintain.
    </p>
    <p>
        As an example, with the following point-to-point quotas:
    </p>
    <ul>
        <li>Oslo S - Oslo Airport: max 10</li>
        <li>Oslo Lufthavn - Hamar: max 5</li>
        <li>Oslo S - Lillehammer: max 5</li>
    </ul>
    <table>
        <caption>
            List with availabilities
        </caption>
        <thead>
            <tr><th><b>From</b></th><th colSpan="4" align="center"><b>To</b></th></tr>
            <tr><td></td><td>Lillestrøm</td><td>Oslo Lufthavn</td><td>Hamar</td><td>Lillehammer</td></tr>
        </thead>
        <tbody>
            <tr><td>Oslo S        </td><td> N/A </td><td> 9  </td><td> N/A </td><td>  N/A </td></tr>
            <tr><td>Lillestrøm    </td><td>   </td><td> N/A </td><td> N/A </td><td>  N/A </td></tr>
            <tr><td>Oslo Lufthavn </td><td>   </td><td>    </td><td> 4 </td><td>  N/A </td></tr>
            <tr><td>Hamar         </td><td>   </td><td>    </td><td>   </td><td>  N/A </td></tr>
        </tbody>
    </table>
    <br />
    <p>
        As seen in the table, many journey queries return no available stock due to point-to-point quotas being strict. Every pair of ods must be specified when using this quota type.
    </p>
    </ExpandablePanel>
    <ExpandablePanel title="Confined stoplist quota" mdxType="ExpandablePanel">
    <p>
        Confined stoplist quotas can be used by operators who want logic similar to stoplist quotas but with variable limitations between the various neighboring stop places. For instance, it is possible to register the following quotas/limitations:
    </p>
    <ul>
        <li>Oslo S - Lillestrøm: max 10</li>
        <li>Lillestrøm - Oslo Lufthavn: max 15</li>
        <li>Oslo Lufthavn - Hamar: max 5</li>
        <li>Hamar - Lillehammer: max 5</li>
    </ul>
    <table>
        <caption>
            List with availabilities
        </caption>
        <thead>
            <tr><th><b>From</b></th><th colSpan="4" align="center"><b>To</b></th></tr>
            <tr><td></td><td>Lillestrøm</td><td>Oslo Lufthavn</td><td>Hamar</td><td>Lillehammer</td></tr>
        </thead>
        <tbody>
            <tr><td>Oslo S        </td><td> 9 </td><td> 9  </td><td> 3 </td><td>  3 </td></tr>
            <tr><td>Lillestrøm    </td><td>   </td><td> 14 </td><td> 3 </td><td>  3 </td></tr>
            <tr><td>Oslo Lufthavn </td><td>   </td><td>    </td><td> 3 </td><td>  3 </td></tr>
            <tr><td>Hamar         </td><td>   </td><td>    </td><td>   </td><td>  4 </td></tr>
        </tbody>
    </table>
    <br />
    <p>
        We can see that the reported availability is strictest quota after subtraction of applicable reservations. Travellers crossing Oslo Lufthavn - Hamar are restricted by the quota of 5, this does not however affect travellers between Oslo S - Oslo Lufthavn, nor Hamar - Lillehammer. Even if Oslo Lufthavn - Hamar and Hamar - Lillehammer has the same quotas, Hamar - Lillehammer has one more available product due to one less reservation.
    </p>
    </ExpandablePanel>
    <h3 {...{
      "id": "multi-tenancy"
    }}>{`Multi-tenancy`}</h3>
    <p>{`Only the authority running the service can access and update quotas on a departure. This is determined by which organisation the request's access token was issued to.`}</p>
    <h2 {...{
      "id": "quota-configurations"
    }}>{`Quota Configurations`}</h2>
    <h3 {...{
      "id": "api-1"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /v1/quota-configurations" mdxType="ExpandablePanel">
Example response
      <pre>
        {` [
    {
        "id": 1,
        "organisationId": 1,
        "name": "ENT:QuotaConfigTree:Ticket",
        "priority": 1,
        "selectionRule": "COMBINED",
        "directionRule": "FROM_RIGHT",
        "consumptionRule": "DIRECT"
    },
    {
        "id": 2,
        "organisationId": 1,
        "name": "ENT:QuotaConfig:OrdinaryTicket",
        "priority": 1,
        "parent": 1
    },
    {
        "id": 3,
        "organisationId": 1,
        "name": "ENT:QuotaConfig:DiscountTicket",
        "priority": 2,
        "selectionRule": "COMBINED",
        "consumptionRule": "DIRECT",
        "directionRule": "FROM_RIGHT",
        "parent": 1
    },
    {
        "id": 4,
        "organisationId": 1,
        "name": "ENT:QuotaConfig:DiscountTicket1",
        "priority": 3,
        "parent": 3
    },
    {
        "id": 5,
        "organisationId": 1,
        "name": "ENT:QuotaConfig:DiscountTicket2",
        "priority": 4,
        "parent": 3
    }
]`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="GET /v1/quota-configurations/:id" mdxType="ExpandablePanel">
Example response GET /v1/quota-configurations/3"
      <pre>
        {`
    {
        "id": 3,
        "organisationId": 1,
        "name": "ENT:QuotaConfig:DiscountTicket",
        "priority": 2,
        "selectionRule": "COMBINED",
        "consumptionRule": "DIRECT",
        "directionRule": "FROM_RIGHT",
        "parent": 1
    }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/quota-configurations" mdxType="ExpandablePanel">
Example request
      <pre>
        {`
    {
        "name": "ENT:QuotaConfig:DiscountTicket",
        "priority": 2,
        "selectionRule": "COMBINED",
        "consumptionRule": "DIRECT",
        "directionRule": "FROM_RIGHT",
        "parent": 1
    }
`}
      </pre>
Example response
      <pre>
        {`
    {
        "id": 99,
        "organisationId": 1,
        "name": "ENT:QuotaConfig:DiscountTicket",
        "priority": 2,
        "selectionRule": "COMBINED",
        "consumptionRule": "DIRECT",
        "directionRule": "FROM_RIGHT",
        "parent": 1
    }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/quota-configurations/:id" mdxType="ExpandablePanel">
Example request PUT /v1/quota-configurations/3
      <pre>
        {`
    {
        "name": "ENT:QuotaConfig:DiscountTicket",
        "priority": 2,
        "selectionRule": "COMBINED",
        "consumptionRule": "DIRECT",
        "directionRule": "FROM_RIGHT",
        "parent": 2
    }
`}
      </pre>
Example response PUT /v1/quota-configurations/3
      <pre>
        {`
    {
        "id": 3,
        "organisationId": 1,
        "name": "ENT:QuotaConfig:DiscountTicket",
        "priority": 2,
        "selectionRule": "COMBINED",
        "consumptionRule": "DIRECT",
        "directionRule": "FROM_RIGHT",
        "parent": 2
    }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="DELETE /v1/quota-configurations/:id" mdxType="ExpandablePanel">
Response 204 if quota configuration is deleted.
    </ExpandablePanel>
    <h3 {...{
      "id": "nesting"
    }}>{`Nesting`}</h3>
    <p>{`The inventory supports the concept of nesting for yield optimization. This allows for similar tickets to be sold at different price levels, typically this is done to allow early birds to acquire the cheapest tickets. Early determination of ticket demand allows for better planning and optimization, like adding extra departures just before christmas or a grand concert, or using smaller trains in periods where you know few people will be travelling.`}</p>
    <p>{`The inventory organize the different quota configuration rules in a tree structure called a nesting tree. The nodes themselves are quota configurations.`}</p>
    <figure>
    <img src={nestingTree} alt="Visualization of a nesting tree." />
    <figcaption>
        This nesting tree includes 5 nodes with a root-node Ticket, and 3 leaf-nodes OrdinaryTicket, DiscountTicket1 and DiscountTicket2. The parent nodes, like the DiscountTicket node, are a grouping of nesting rules, and as a parent node it contains a selection rule, a consumption rule and a direction rule.
    </figcaption>
    </figure>
    <h3 {...{
      "id": "selection-rule"
    }}>{`Selection rule`}</h3>
    <p>{`The selection rule is a hint to the offers module of whether the different child price levels should hide each other. The following two values are allowed:`}</p>
    <ul>
      <li parentName="ul">{`COMBINED - The combined selection rule is a hint to offer tickets as they appear. If the end customer wants 4 tickets, and 5 Discount1 and 2 Discount2 are available he/she should be offered 2 Discount2 tickets and 2 Discount1 tickets.`}</li>
      <li parentName="ul">{`SINGLE - The single selection rule is a hint that the nesting rules should hide each other. That is, the end customer should be offered only one price level for all tickets. Thus if the customer wants 4 tickets, and 5 Discount1 and 2 Discount2 are available, the customer should be offered 4 Discount1 tickets. There is not enough Discount2 tickets available, therefore the Discount1 price level has hidden the Discount2 level.`}</li>
    </ul>
    <p>{`The value of the selection rules does not influence counting in inventory.`}</p>
    <h3 {...{
      "id": "direction-rule"
    }}>{`Direction rule`}</h3>
    <p>{`The direction rule determines which nodes overflowing consumption should be placed in first.`}</p>
    <ul>
      <li parentName="ul">{`FROM_RIGHT - From right means that when moving over-consumption inventory starts with the node with the highest priority in the same group, in other words the rightmost node.`}</li>
      <li parentName="ul">{`FROM_LEFT - From left means that when moving over-consumption it starts with the node with the lowest priority in the same group, in other words the first node to the left.`}</li>
    </ul>
    <p>{`If overflowing consumption can't be placed in the first node the direction rule dictates it continues to the next node up or down the priority list.
The direction rule also affect the direction when using the BY_PRIORITY rule. `}</p>
    <h3 {...{
      "id": "consumption-rule"
    }}>{`Consumption rule`}</h3>
    <p>{`The consumption rule affects how reservations in inventory consumes the different price levels. It determines if the quota should consume the reservation immediately or act as if its own reservations are overflowing consumption. `}</p>
    <ul>
      <li parentName="ul">{`DIRECT - A reservation of a product is counted as a reservation of a product as long as the reservation quantity does not surpass the associated quota. Any overflowing quantity is counted as over-consumption and moved towards the first quota to the left or rightmost quota, determined by the Direction rule.`}</li>
      <li parentName="ul">{`BY_PRIORITY - A reservation of a product is viewed as overflowing and moved to the first quota on the left, or rightmost (decided by the Direction rule) product in the same group. E.g with the Direction rule set to FROM_RIGHT; Given 5 available DiscountTicket1 and 5 DiscountTicket2, a reservation of 3 DiscountTicket1 and 2 DiscountTicket2 would have the same result as a reservation of 5 DiscountTicket2. Thus, unless DiscountTicket2 is "filled", there is always 5 DiscountTicket1 available.`}</li>
    </ul>
    <h3 {...{
      "id": "nodes-closing-other-nodes"
    }}>{`Nodes closing other nodes`}</h3>
    <p>{`The quota configurations can be configured to close other nodes in the same or other trees. This is to make sure that certain quotas are never offered if some other quota is empty.
Say there are two different nesting trees for two different products, product A and B. A is a premium product and B an ordinary/standard product.
If there for some reason is a high number of product B sales, a situation where Standard-tickets are more expensive than Premium-tickets could occur because B has exhausted several quotas.
To avoid this the nodes in the nesting tree for product A can be configured to automatically close when certain nodes in the nesting tree for B are empty.
The total capacity of the nesting tree is not affected. This means that nodes higher in the tree will still be able to overflow into the closed nodes.`}</p>
    <h3 {...{
      "id": "over-consumption"
    }}>{`Over-consumption`}</h3>
    <p>{`All nesting nodes has a priority which, in combination with the direction rule, determines ordering among siblings in the tree.
In the above example, the nodes could have the following priorities:`}</p>
    <ul>
      <li parentName="ul">{`Ticket priority=1`}
        <ul parentName="li">
          <li parentName="ul">{`OrdinaryTicket priority=1`}</li>
          <li parentName="ul">{`DiscountTicket priority=2`}
            <ul parentName="li">
              <li parentName="ul">{`DiscountTicket1 priority=1`}</li>
              <li parentName="ul">{`DiscountTicket2 priority=2`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p>{`Quotas with nesting nodes in the same tree can be overspended. That is, it is possible to consume more than the quota for OrdinaryTicket, the overflowing quantity will then be deducted from the stock of cheaper tickets.
This is the whole point of grouping nodes together in the same nesting tree.`}</p>
    <p>{`If there had been another sibling GroupTicket (priority=3) of OrdinaryTicket and DiscountTicket,
and the direction rule of DiscountTicket is set to FROM_RIGHT, the order of recipients of overspended consumption from OrdinaryTicket would be:`}</p>
    <ol>
      <li parentName="ol">{`GroupTicket`}</li>
      <li parentName="ol">{`DiscountTicket2`}</li>
      <li parentName="ol">{`DiscountTicket1`}</li>
    </ol>
    <p>{`Only leaf-nodes are associated with quotas, when the DiscountTicket (parent) node receive overspended consumption it forwards it to its children.
If one of these children are parent nodes this is repeated, and the nodes are traversed in the direction set by the direction rule.`}</p>
    <h3 {...{
      "id": "quota-behavior-in-nesting-trees"
    }}>{`Quota behavior in nesting trees`}</h3>
    <p>{`It is important to avoid mixing different quota behaviors in the same nesting tree. If one is using Point-to-point quotas for DiscountTicket1 the same od-list should be used in a point-to-point quota for DiscountTicket2. Mixing for different quota behaviors in the same nesting tree results in undefined results.`}</p>
    <h2 {...{
      "id": "reservations"
    }}>{`Reservations`}</h2>
    <p>{`A reservation is a claim on some of the availability of something, across a distance. A reservation must have a departure, a valid origin and destination on this departure, and one or more reservation lines. Reservation lines points to the product to reserve, a status, and the quantity. This means a reservation can reserve multiple products.`}</p>
    <p>{`Reservations decrement the availability of quotas which they apply to. A reservation might affect multiple quotas depending on quota type and the origin and destination of the reservation.`}</p>
    <h3 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h3>
    <p>{`All reservations made must belong to a defined departure, which means that a line must also be defined.`}</p>
    <h3 {...{
      "id": "api-2"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /v1/reservations?datedServiceJourney=:datedServiceJourney" mdxType="ExpandablePanel">
Example response GET /v1/reservations?datedServiceJourney=ENT:DatedServiceJourney:16308
The following fields can be passed as query parameters in order to further filter the reservations on a departure:
* createdAfter
* changedAfter
* origin
* destination
* product
* status
      <pre>
        {`[
    {
        "id": 1000,
        "origin": "NSR:StopPlace:337",
        "destination": "NSR:StopPlace:548",
        "datedServiceJourney": "ENT:DatedServiceJourney:16308",
        "created": "2019-07-30T08:51:21.750326Z",
        "changed": "2019-07-30T08:51:21.753412Z",
        "createdBy": "1",
        "reservationLines": [
            {
                "id": 2000,
                "productId": "ABC:PreassignedFareProduct:Ticket",
                "amount": 1,
                "created": "2019-07-30T08:51:21.755475Z",
                "changed": "2019-07-30T08:51:21.755505Z",
                "status": "DRAFT"
            }
        ]
    },
    {
        "id": 1001,
        "origin": "NSR:StopPlace:337",
        "destination": "NSR:StopPlace:659",
        "datedServiceJourney": "ENT:DatedServiceJourney:16308",
        "created": "2019-07-30T13:55:14.825956Z",
        "changed": "2019-07-31T11:00:04.817252Z",
        "createdBy": "1",
        "reservationLines": [
            {
                "id": 2001,
                "productId": "ABC:PreassignedFareProduct:Ticket",
                "amount": 1,
                "created": "2019-07-30T13:55:14.828975Z",
                "changed": "2019-07-31T11:00:04.889138Z",
                "status": "EXPIRED"
            }
        ]
    }
]`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="GET /v1/reservations/:id" mdxType="ExpandablePanel">
Example GET /v1/reservations/1000
      <pre>
        {`
    {
        "id": 1000,
        "origin": "NSR:StopPlace:337",
        "destination": "NSR:StopPlace:548",
        "datedServiceJourney": "ENT:DatedServiceJourney:16308",
        "created": "2019-07-30T08:51:21.750326Z",
        "changed": "2019-07-30T08:51:21.753412Z",
        "createdBy": "1",
        "reservationLines": [
            {
                "id": 2000,
                "productId": "ABC:PreassignedFareProduct:Ticket",
                "amount": 1,
                "created": "2019-07-30T08:51:21.755475Z",
                "changed": "2019-07-30T08:51:21.755505Z",
                "status": "DRAFT"
            }
        ]
    }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/reservations" mdxType="ExpandablePanel">
Example request
      <pre>
        {`
  {
    "origin": "NSR:StopPlace:337",
    "destination": "NSR:StopPlace:602",
    "datedServiceJourneyId": "ENT:DatedServiceJourney:16308",
    "reservationLines": [
    	{
    		"amount": 1,
    		"productId": "ABC:PreassignedFareProduct:Ticket",
    		"status": "DRAFT"
    	}
    ]
  }
`}
      </pre>
Example response
      <pre>
        {`
  {
    "id": 1002,
    "origin": "NSR:StopPlace:337",
    "destination": "NSR:StopPlace:602",
    "datedServiceJourneyId": "ENT:DatedServiceJourney:16308",
    "created": "2019-08-01T08:51:21.750326Z",
    "changed": "2019-08-01T08:51:21.753412Z",
    "createdBy": "1",
    "reservationLines": [
    	{
    	    "id": 2002,
    		"amount": 1,
    		"productId": "ABC:PreassignedFareProduct:Ticket",
    		"created": "2019-08-01T08:51:21.755475Z",
            "changed": "2019-08-01T08:51:21.755505Z",
    		"status": "DRAFT"
    	}
    ]
  }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="POST /v1/reservations/:reservationId/reservation-lines" mdxType="ExpandablePanel">
Example request POST /v1/reservations/1002/reservation-lines (add reservation line)
      <pre>
        {`
    {
        "amount": 3,
    	"productId": "ABC:PreassignedFareProduct:Ticket",
    	"status": "DRAFT"
    }
`}
      </pre>
Example response POST /v1/reservations/1002/reservation-lines
      <pre>
        {`
    {
        "id": 2003,
        "amount": 2,
        "productId": "ABC:PreassignedFareProduct:Ticket",
        "created": "2019-08-01T11:50:21.755475Z",
        "changed": "2019-08-01T11:50:21.755505Z",
        "status": "DRAFT"
    }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/reservations/:reservationId/reservation-lines" mdxType="ExpandablePanel">
Example request PUT /v1/reservations/1002/reservation-lines
      <pre>
        {`[
    {
        "id": 2000,
        "productId": "ABC:PreassignedFareProduct:Ticket",
        "amount": 1,
        "status": "CONFIRMED"
    },
    {
        "id": 2001,
        "productId": "ABC:PreassignedFareProduct:Ticket",
        "amount": 1,
        "status": "CONFIRMED"
    }
]`}
      </pre>
Example response PUT /v1/reservations/1002/reservation-lines
      <pre>
        {`[
    {
        "id": 2000,
        "productId": "ABC:PreassignedFareProduct:Ticket",
        "amount": 1,
        "created": "2019-07-30T08:51:21.755475Z",
        "changed": "2019-07-30T08:55:21.755505Z",
        "status": "CONFIRMED"
    },
    {
        "id": 2001,
        "productId": "ABC:PreassignedFareProduct:Ticket",
        "amount": 1,
        "created": "2019-07-30T08:51:21.755475Z",
        "changed": "2019-07-30T08:55:21.755505Z",
        "status": "CONFIRMED"
    }
]`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="PUT /v1/reservations/:reservationId/reservation-lines/:reservationLineId" mdxType="ExpandablePanel">
Example request PUT /v1/reservations/1002/reservation-lines/2003 (update reservation line)
      <pre>
        {`
    {
        "amount": 2,
        "productId": "ABC:PreassignedFareProduct:Ticket",
        "status": "CONFIRMED"
    }
`}
      </pre>
Example response PUT /v1/reservations/1002/reservation-lines/2003 (update reservation line)
      <pre>
        {`
    {
        "id": 2003,
        "amount": 2,
        "productId": "ABC:PreassignedFareProduct:Ticket",
        "created": "2019-08-01T11:50:21.755475Z",
        "changed": "2019-08-01T12:00:21.755505Z",
        "status": "CONFIRMED"
    }
`}
      </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "applicability-to-quotas"
    }}>{`Applicability to quotas`}</h3>
    <p>{`The applicability of a reservation to a quota is determined by the reserved product being in the quota product-list. It is also dependent on the quota type:`}</p>
    <h4 {...{
      "id": "sales-quota-1"
    }}>{`Sales quota`}</h4>
    <p>{`The reservation always apply.`}</p>
    <h4 {...{
      "id": "stoplist-quota-1"
    }}>{`Stoplist quota`}</h4>
    <p>{`The reservation always apply.`}</p>
    <h4 {...{
      "id": "point-to-point-quota-p2p-1"
    }}>{`Point-to-point quota (P2P)`}</h4>
    <p>{`The reservation-od must be included in the quota's od-list.`}</p>
    <h4 {...{
      "id": "confined-stoplist-quota-1"
    }}>{`Confined stoplist quota`}</h4>
    <p>{`The reservation-od must overlap with at least one of the segments created by letting the quota ods divide the stoplist into segments.`}</p>
    <h3 {...{
      "id": "statuses"
    }}>{`Statuses`}</h3>
    <p>{`A reservation have a status indicating where the reservation is in the purchase. A status also indicate how the reservation should affect the calculation of stock. A reservation can one of the following statuses:`}</p>
    <h4 {...{
      "id": "draft"
    }}>{`DRAFT`}</h4>
    <p>{`Draft is a "soft-reservation" status. This means that whenever a user is in the process of buying a product we reserve the product with the status DRAFT. All reservations with DRAFT status will affect stock calculation similarly as reservations with status CONFIRMED. This way we're protected from overselling products and the customer is guaranteed their selected products after purchase.`}</p>
    <p>{`Reservations with status DRAFT can only change status to either EXPIRED or CONFIRMED.`}</p>
    <h4 {...{
      "id": "expired"
    }}>{`EXPIRED`}</h4>
    <p>{`If a customer decides not to complete a purchase, all soft-reservations made by the customer will eventually expire. When a reservation expire all reservation lines change status to EXPIRED. This means that the products are no longer reserved and the reservations won't affect stock calculation.`}</p>
    <p>{`Reservations with status EXPIRED can not change to any other status.`}</p>
    <h4 {...{
      "id": "confirmed"
    }}>{`CONFIRMED`}</h4>
    <p>{`CONFIRMED means the reservation is confirmed by either a successful transactions or some other way. Confirmed reservations can no longer expire and will affect stock calculation.`}</p>
    <p>{`Reservations with status CONFIRMED can only change status to CANCELLED.`}</p>
    <h4 {...{
      "id": "cancelled"
    }}>{`CANCELLED`}</h4>
    <p>{`If a customer cancels a confirmed reservation the status changes to CANCELLED. Cancelling a reservation is described more in detail below.`}</p>
    <h4 {...{
      "id": "releasing"
    }}>{`RELEASING`}</h4>
    <p>{`When a reservation is cancelled a releasing reservation is made to release the products. Cancelled and releasing reservations are described more in detail below.`}</p>
    <h3 {...{
      "id": "possible-reservation-status-transitions"
    }}>{`Possible reservation status transitions`}</h3>
    <figure>
    <img src={reservationStatusChange} alt="Visualization of reservation status transitions" />
    <figcaption>
        Flowchart example with different outcomes of a reservation and its possible states.
    </figcaption>
    </figure>
    <h3 {...{
      "id": "cancelling-a-reservation"
    }}>{`Cancelling a reservation`}</h3>
    <p>{`To cancel a reservation it must exist in Inventory and have the status CONFIRMED. Reservations with any other status can not transition to a cancelled status.`}</p>
    <p>{`When a reservation is cancelled the status changes to CANCELLED and the products made available again. Just making the originally reserved products available again can create situations where the price presented to customers change after a cancellation because there is now more lower priced products available. To prevent this from happening it's possible to configure what product to add back after cancellations.`}</p>
    <p>{`When adding products back, a new reservation is made, the status RELEASING, and a negative quantity to negate the original reservation.`}</p>
    <h3 {...{
      "id": "multi-tenancy-1"
    }}>{`Multi tenancy`}</h3>
    <p>{`The reservation api is available to all operators, but only the authority running the service can access all reservations on a departure. Other operators can only access reservations they have created themselves.`}</p>
    <h2 {...{
      "id": "stock"
    }}>{`Stock`}</h2>
    <p>{`The stock service is used to calculate available stock, meaning the quantity of available resources that are constrained by quotas on the departure. The query for available stock is on a given dated service journey from an origin to a destination. The stock calculation can roughly be summarized as follows:`}</p>
    <p>{`Find all quotas and reservations on a dated service journey
Filter only quotas applicable to the journey segment of the query
For each remaining quota q: find reservations applicable to q
Subtract reservationQuantities from quotas
Apply nesting`}</p>
    <p>{`The result displays the available stock on the segment between the starting and end stop place for that journey.`}</p>
    <h3 {...{
      "id": "api-3"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /v1/stock?datedServiceJourney=:datedServiceJourney&origin=:origin&destination=:destination" mdxType="ExpandablePanel">
Example response GET /v1/stock?datedServiceJourney=ENT:DatedServiceJourney:16308&origin=NSR:StopPlace:337&destination=NSR:StopPlace:602
      <pre>
        {`
    {
        "datedServiceJourneyId": "ENT:DatedServiceJourney:16308",
        "origin": "NSR:StopPlace:337",
        "destination": "NSR:StopPlace:602",
        "organisationId": "999",
        "stock": [
            {
                "nestingGroup": "ABC:NestingRule:Ticket",
                "aggregatedAvailability": 8,
                "priority": 1,
                "selectionRule": "COMBINED",
                "consumptionRule": "DIRECT",
                "components": [
                    {
                        "nestingGroup": "ABC:NestingRule:Discount",
                        "aggregatedAvailability": 8,
                        "priority": 2,
                        "selectionRule": "COMBINED",
                        "consumptionRule": "DIRECT",
                        "components": [
                            {
                                "nestingGroup": "ABC:FareQuotaFactor:Discount1",
                                "aggregatedAvailability": 8,
                                "priority": 3,
                                "products": [
                                    "ABC:PreassignedFareProduct:Ticket"
                                ],
                                "leftInQuota": 8
                            }
                        ]
                    }
                ]
            }
        ]
    }
`}
      </pre>
    </ExpandablePanel>
    <p>{`The stock response contains the following fields:`}</p>
    <ul>
      <li parentName="ul">{`datedServiceJourney: The id for the given dated service journey`}</li>
      <li parentName="ul">{`origin: The starting stop place`}</li>
      <li parentName="ul">{`destination: The destination stop place`}</li>
      <li parentName="ul">{`organisation: The owner of the departure`}</li>
      <li parentName="ul">{`stock: A list of available stock components`}</li>
    </ul>
    <p>{`The stock components can either be a root node of a quota nesting tree, or a simple quota without any nesting.`}</p>
    <h4 {...{
      "id": "simple-quotas"
    }}>{`Simple quotas`}</h4>
    <p>{`These components contain the two following fields:`}</p>
    <ul>
      <li parentName="ul">{`products: A list of products that the quota constrains`}</li>
      <li parentName="ul">{`leftInQuota: The remaining quantity on this quota`}</li>
    </ul>
    <h4 {...{
      "id": "nested-quotas"
    }}>{`Nested quotas`}</h4>
    <p>{`The fields of a quota nesting tree node are somewhat different whether this is a parent-node or a leaf-node. Only leaf-nodes have quotas associated with it.`}</p>
    <p><strong parentName="p">{`Parent nodes`}</strong>{` has the following fields:`}</p>
    <ul>
      <li parentName="ul">{`nestingGroup: Name of this quota nesting rule`}</li>
      <li parentName="ul">{`aggregatedAvailability: The maximum quantity that can be reserved of quotas associated with decendants of this quota nesting rule`}</li>
      <li parentName="ul">{`priority: The priority for quota nesting rule`}</li>
      <li parentName="ul">{`components: A list of quota nesting rule children`}</li>
      <li parentName="ul">{`consumptionRule: The consumption rule set on the quota nesting parent`}</li>
      <li parentName="ul">{`selectionRule: The selection rule set on the quota nesting parent`}</li>
    </ul>
    <p><strong parentName="p">{`Leaf nodes`}</strong>{` has the following fields:`}</p>
    <ul>
      <li parentName="ul">{`nestingGroup: Name of this quota nesting rule`}</li>
      <li parentName="ul">{`aggregatedAvailability: The maximum quantity that can be reserved of this resource, including possible quantities that can carry over to nested, higher prioritized, sibling components`}</li>
      <li parentName="ul">{`priority: The priority for quota nesting rule`}</li>
      <li parentName="ul">{`products: A list of product ids for the quota associated with the quota nesting rule`}</li>
      <li parentName="ul">{`leftInQuota: The remaining available quantity of the resource`}</li>
      <li parentName="ul">{`purchaseWindowStart: The start of the purchaseWindow on the associated quota`}</li>
      <li parentName="ul">{`purchaseWindowStop: The end of the purchaseWindow on the associated quota`}</li>
    </ul>
    <h3 {...{
      "id": "stock-overrides"
    }}>{`Stock overrides`}</h3>
    <p>{`The fields aggregatedAvailability and leftInQuota are set to zero if the associated quota is outside its purchaseWindow, or if a closing node is exhausted.
Note that the availability of the node is not lost from the tree, only from the node itself. In other words, aggregatedAvailability is not reduced.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      